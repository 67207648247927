<template>
  <div>
    <div v-if="state == 'view'">
      <v-chip
        color="red"
        class="ma-1 white--text infraction-chip"
        :class="{'is-not-default-infraction-chip': !isDefaultChip}"
        close
        @click="editChip()"
        @click:close="$emit('onDeleteChip', infractionChip)"
      >
        {{ infractionChip.criteria.name }}
        {{ infractionChip.operator }}
        {{ infractionChip.infractionCount }}
      </v-chip>
    </div>
    <div
      v-if="state == 'edit'"
      class="new-criteria-filter"
      :class="{ 'edit-mode-on-mobile': IS_MOBILE }"
    >
      <v-autocomplete
        class="pa-0 filter-autocomplete mr-2"
        :items="displayCriteria"
        v-model="selectedCriterion"
        :label="
          $localizationService.localize(
            'drp.criteria_filter.chip.label.select_inf'
          )
        "
        item-text="name"
        return-object
      >
        <template v-slot:item="data">
          {{ data.item.name }}
        </template>
      </v-autocomplete>
      <v-select
        class="pa-0 operators mr-2"
        :items="operators"
        v-model="selectedOperator"
        :label="
          $localizationService.localize(
            'drp.criteria_filter.chip.label.operator'
          )
        "
      >
      </v-select>
      <v-text-field
        class="pa-0 text-field-width"
        :label="
          $localizationService.localize('drp.criteria_filter.chip.label.value')
        "
        v-model="selectedInfractionCount"
        type="number"
      >
      </v-text-field>
      <v-icon
        class="ml-2"
        color="green"
        @click="save()"
        :disabled="
          !selectedCriterion ||
          !selectedOperator ||
          !String(selectedInfractionCount)
        "
        >mdi-check</v-icon
      >
      <v-icon class="ml-2" color="red" @click="cancel()">mdi-close</v-icon>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { IS_MOBILE } from "../../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "InfractionChip",

  props: {
    displayCriteria: Array,
    infractionChip: {
      type: Object,
      default: undefined,
    },
    selectedCriteriaChips: Array,
    isDefaultChip: Boolean,
  },

  data: () => ({
    IS_MOBILE,

    operators: ["<", ">", "=", "!="],

    state: undefined,

    selectedCriterion: undefined,
    selectedOperator: undefined,
    selectedInfractionCount: undefined,
  }),

  methods: {
    save() {
      if (
        this.selectedCriteriaChips.some(
          (chip) => chip.criteria.id == this.selectedCriterion.id
            && chip.operator == this.selectedOperator
            && chip.infractionCount == this.selectedInfractionCount,
        )
      ) {
        Vue.toasted.error(
          locService.localize("drp.criteria_filter.chip.err.exists"),
          { duration: 3000 },
        );
        return;
      }

      this.$emit("onSave", {
        criteria: this.selectedCriterion,
        operator: this.selectedOperator,
        infractionCount: this.selectedInfractionCount,
      });
    },

    editChip() {
      this.selectedCriterion = this.infractionChip.criteria;
      this.selectedOperator = this.infractionChip.operator;
      this.selectedInfractionCount = this.infractionChip.infractionCount;

      this.state = "edit";
    },

    cancel() {
      if (this.infractionChip) {
        this.state = "view";
      }

      this.$emit("onCancel");
    },
  },
  mounted() {
    if (this.infractionChip) {
      this.state = "view";
    } else {
      this.state = "edit";
    }
  },
};
</script>

<style lang="scss">
.infraction-chip {
  &.is-not-default-infraction-chip {
    border: 3px solid orange !important;
  }
}
.new-criteria-filter {
  display: flex;
  .v-input__slot {
    margin-bottom: 0 !important;
  }
  .v-input {
    margin-top: 0 !important;
  }
  .v-text-field__details,
  .v-label--active {
    display: none !important;
  }
  .filter-autocomplete {
    input {
      width: 150px !important;
    }
  }
  .operators {
    width: 85px !important;
  }
  .text-field-width {
    width: 85px !important;
  }

  &.edit-mode-on-mobile {
    .filter-autocomplete {
      input {
        width: 90px !important;
      }
    }
    .operators {
      width: 65px !important;
    }
    .text-field-width {
      width: 65px !important;
    }
  }
}
</style>
